import api from "service/axios.service";
import {
  PRA_REQUEST,
  PRA_SUCCESS,
  PRA_FAIL
} from "../constants/praConstants";

export const praData = (id) => async (dispatch) => {
  dispatch({ type: PRA_REQUEST });
  try {
    const res = await api.get("/v1/pra/pt-BR/");
    const { data } = res || {};

    dispatch({
      type: PRA_SUCCESS,
      payload: id && data ? data[0] : data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: PRA_FAIL,
      payload: error.response?.data.message
        ? error.message.data.message
        : error.message,
    });
  }
};
