/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
/* eslint-disable prettier/prettier */
import React from "react";
import { NavLink } from "react-router-dom";

function Button({
  onClick = () => { },
  style = null,
  disabled = false,
  children,
  size = "",
  variant = "",
  type = "#",
  href = "",
  multipleState = false,
  loading = false,
}) {
  const classNameMap = {
    "primary": {
      sm: "tcf-btn tcf-btn--primary tcf-btn--small",
      md: "tcf-btn tcf-btn--primary tcf-btn--medium",
    },
    "outline-primary": {
      sm: "tcf-btn tcf-btn--outline tcf-btn--small",
      md: "tcf-btn tcf-btn--outline tcf-btn--medium",
    },
    "icon-primary": {
      sm: "tcf-btn tcf-btn--primary tcf-btn--icon tcf-btn--small",
      md: "tcf-btn tcf-btn--primary tcf-btn--icon tcf-btn--medium",
    },
    "icon-outline": {
      sm: "tcf-btn tcf-btn--outline tcf-btn--icon tcf-btn--small",
      md: "tcf-btn tcf-btn--outline tcf-btn--icon tcf-btn--medium",
    },
    "link-light": "tcf-btn-link tcf-btn-link--light",
    "link-dark": "tcf-btn-link tcf-btn-link--dark",
    "nav-link-light": {
      default: "tcf-btn-link tcf-btn-link--light",
      active: "tcf-btn-link tcf-btn-link--light tcf-btn-link--light--active",
    },
    "nav-link-dark": {
      default: "tcf-btn-link tcf-btn-link--dark",
      active: "tcf-btn-link tcf-btn-link--dark tcf-btn-link--dark--active",
    },
  };

  const getButtonClass = (variant, size, isActive) => {
    if (variant.includes("link")) {
      const linkClass =
        classNameMap[variant][isActive ? "active" : "default"] ||
        classNameMap[variant];
      return linkClass;
    }
    return classNameMap[variant]?.[size] || "";
  };

  const fixedClass = `${multipleState ? "tcf-btn-multiple-state" : ""} ${getButtonClass(variant, size)} ${loading ? "show-spinner" : ""}`;

  return (
    <>
      {variant.includes("link") ? (
        <NavLink to={href} style={style} onClick={() => onClick()} className={({ isActive }) => getButtonClass(variant, size, isActive)}>
          {children}
        </NavLink>
      ) : (
        <button onClick={() => onClick()} type={type} style={style} disabled={disabled} className={fixedClass}>
          {children}
        </button>
      )}
    </>
  );
}

export default Button;
