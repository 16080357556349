/* eslint-disable prettier/prettier */
import React from "react";
import i18n from "i18next";
import { Link } from "react-router-dom";
import { scrollTo } from "utils/general-functions";
import { Header, Hero, ButtonGroup, Button, Blog, Footer, ConsentCookie, Landingpage } from "components";
const { SobreOTermometro, EntendaOsDados, Ferramentas, CodigoFlorestal } = Landingpage;

function Landing() {

  return (
    <div className="tcf-landing-page" id="home">
      <Header />
      <Hero size="md">
        <h1>{i18n.t("theThermometer")}</h1>
        <p>{i18n.t("legendParallax")}</p>
        <ButtonGroup center>
          <Button onClick={() => scrollTo("about")} variant="link-dark" size="md">
            {i18n.t("know")}
          </Button>
          <Link to="/plataforma">
            <Button variant="primary" size="md">
              {i18n.t("access_platform")}
            </Button>
          </Link>
        </ButtonGroup>
      </Hero>
      <SobreOTermometro />
      <Blog />
      <CodigoFlorestal />
      <Ferramentas />
      <EntendaOsDados />
      <Footer />
      <ConsentCookie />
    </div>
  );
}

export default Landing;
