/* eslint-disable react/jsx-no-useless-fragment */
import React, { useRef } from "react";
import ReportSizeFilter from "components/ReportSizeFilter";
import { DownloadGraphFunction } from "../components";

const TeritorialCut = ({ item }) => {
  const ref = useRef();
  const { title, component, col, hasFilter } = item;

  const colClassMap = {
    full: "tcf-map-report--body-territorialcut-item--third",
    fullplus: "tcf-map-report--body-territorialcut-item--third--plus",
  };

  const itemClass = `tcf-map-report--body-territorialcut-item ${colClassMap[col] || ""}`;

  return (
    <>
      {item && (
        <div ref={ref} className={itemClass}>
          <div className="tcf-map-report--body-territorialcut-item--title">
            {title}
            {hasFilter && <ReportSizeFilter />}
            <DownloadGraphFunction documentRef={ref} documentTitle={title} />
          </div>

          <div className="tcf-map-report--body-territorialcut-item--body">
            {component}
          </div>
        </div>
      )}
    </>
  );
};

export default TeritorialCut;
