import React from "react";

function ToggleButton({
  children,
  id,
  type = "radio",
  variant = "primary",
  name,
  value,
  checked,
  size = "md",
  ...rest
}) {
  const classNames = (variant, size) => {
    let className = `tcf-btn tcf-btn--primary tcf-btn--medium`;
    if (variant === "primary" && size === "md") {
      className = `tcf-btn tcf-btn--primary tcf-btn--medium`;
    } else if (variant === "primary" && size === "sm") {
      className = `tcf-btn tcf-btn--primary tcf-btn--small`;
    } else if (variant === "outline-primary" && size === "md") {
      className = `tcf-btn tcf-btn--outline tcf-btn--medium`;
    } else if (variant === "outline-primary" && size === "sm") {
      className = `tcf-btn tcf-btn--outline tcf-btn--small`;
    } else if (variant === "icon" && size === "md") {
      className = `tcf-btn tcf-btn--icon tcf-btn--medium`;
    }

    return className;
  };
  return (
    <>
      <input
        className="tcf-btn-check"
        name={name}
        type={type}
        id={id}
        value={value}
        checked={checked}
        {...rest}
      />
      <label
        htmlFor={id}
        className={`tcf-btn-check-label ${classNames(variant, size)}`}
      >
        {children}
      </label>
    </>
  );
}

export default ToggleButton;
