import * as statisticsActions from "store/actions/statisticsActions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "components";

const PdfButton = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.statistics);

  const onOpen = () => {
    dispatch(statisticsActions.openReport());
  };

  return (
    <Button
      loading={loading}
      multipleState
      variant="outline-primary"
      size="md"
      onClick={() => onOpen()}
      style={{
        margin: `0px 0 0 10px`,
      }}
    >
      <span className="spinner tcf-d-inline-block">
        <span className="bounce1" />
        <span className="bounce2" />
        <span className="bounce3" />
      </span>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="8"
          y="12"
          width="6"
          height="18"
          fill="#78C740"
          stroke="#78C753"
        />
        <rect
          x="18"
          y="15"
          width="6"
          height="15"
          fill="#78C740"
          stroke="#78C753"
        />
        <rect
          x="28"
          y="5"
          width="6"
          height="25"
          fill="#78C740"
          stroke="#78C753"
        />
      </svg>
    </Button>
  );
};

export default PdfButton;
