/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { formatNumber } from "helpers/utils";
import NivoCustomTooltip from "components/NivoCustomTooltip";
import i18n from "i18next";

const CustomLegend = ({ data }) => (
  <div className="tcf-map-report-legality--legend">
    <div className="tcf-map-report-legality--legend-item">
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="12" height="12" fill="#AC6C29" />
      </svg>

      <div className="tcf-map-report-legality--legend-item-info">
        <span className="tcf-map-report-legality--legend-item-info-title">
          {i18n.t("passiveRL")}
        </span>
        {/* <span className="tcf-map-report-legality--legend-item-info-percent">
          30% - 99.999ha
        </span> */}
      </div>
    </div>
    <div className="tcf-map-report-legality--legend-item">
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="12" height="12" fill="#AC6C29" fillOpacity="0.5" />
        <rect y="0.5" width="12" height="12" fill="url(#lines1)" />
        <defs>
          <pattern
            id="lines1"
            width="18.384776310850235"
            height="18.384776310850235"
            patternUnits="userSpaceOnUse"
          >
            <rect
              width="18.384776310850235"
              height="18.384776310850235"
              fill="#AC6C2980"
              stroke="rgba(255, 0, 0, 0.1)"
              strokeWidth="0"
            />
            <path
              d="
                    M 0 -18.384776310850235 L 36.76955262170047 18.384776310850235
                    M -18.384776310850235 -18.384776310850235 L 18.384776310850235 18.384776310850235
                    M -18.384776310850235 0 L 18.384776310850235 36.76955262170047
                "
              strokeWidth="6"
              stroke="#AC6C29"
              strokeLinecap="square"
            />
          </pattern>
        </defs>
      </svg>

      <div
        className="tcf-map-report-legality--legend-item-info"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <span className="tcf-map-report-legality--legend-item-info-title">
          {i18n.t("passiveAPP")}
        </span>
        {/* <span className="tcf-map-report-legality--legend-item-info-percent">
          30% - 99.999ha
        </span> */}
      </div>
    </div>
    <div
      className="tcf-map-report-legality--legend-item"
      style={{ display: "flex", alignItems: "center", gap: "8px" }}
    >
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="12" height="12" fill="#78C753" />
      </svg>

      <div
        className="tcf-map-report-legality--legend-item-info"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <span className="tcf-map-report-legality--legend-item-info-title">
          {i18n.t("remainingRL")}
        </span>
        {/* <span className="tcf-map-report-legality--legend-item-info-percent">
          30% - 99.999ha
        </span> */}
      </div>
    </div>
    <div
      className="tcf-map-report-legality--legend-item"
      style={{ display: "flex", alignItems: "center", gap: "8px" }}
    >
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="12" height="12" fill="url(#lines2)" />
        <rect
          y="0.5"
          width="12"
          height="12"
          fill="rgb(128, 198, 77)"
          fillOpacity="0.5"
        />

        <defs aria-hidden="true">
          <pattern
            id="lines2"
            width="18.384776310850235"
            height="18.384776310850235"
            patternUnits="userSpaceOnUse"
          >
            <rect
              width="18.384776310850235"
              height="18.384776310850235"
              fill="rgba(128, 198, 77, 0.5)"
              stroke="rgba(255, 0, 0, 0.1)"
              strokeWidth="0"
            />
            <path
              d="
                    M 0 -18.384776310850235 L 36.76955262170047 18.384776310850235
                    M -18.384776310850235 -18.384776310850235 L 18.384776310850235 18.384776310850235
                    M -18.384776310850235 0 L 18.384776310850235 36.76955262170047
                "
              strokeWidth="6"
              stroke="rgb(128, 198, 77)"
              strokeLinecap="square"
            />
          </pattern>
        </defs>
      </svg>

      <div
        className="tcf-map-report-legality--legend-item-info"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <span className="tcf-map-report-legality--legend-item-info-title">
          {i18n.t("remainingAPP")}
        </span>
        {/* <span className="tcf-map-report-legality--legend-item-info-percent">
          30% - 99.999ha
        </span> */}
      </div>
    </div>

    <div
      className="tcf-map-report-legality--legend-item"
      style={{ display: "flex", alignItems: "center", gap: "8px" }}
    >
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="12" height="12" fill="#6D945C" />
      </svg>

      <div
        className="tcf-map-report-legality--legend-item-info"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <span className="tcf-map-report-legality--legend-item-info-title">
          {i18n.t("surplus")}
        </span>
        {/* <span className="tcf-map-report-legality--legend-item-info-percent">
          30% - 99.999ha
        </span> */}
      </div>
    </div>
  </div>
);

function NivoBar({ data }) {
  const customColors = ["#A66C29", "#A66C29", "#80C64D", "#80C64D", "#6D945C"];

  return (
    <div className="tcf-nivo-bar">
      <div className="tcf-map-report--body-territorialcut-item--body--bar">
        <ResponsiveBar
          data={data}
          keys={[
            "Passivo APP",
            "Passivo RL",
            "Remanescente RL",
            "Remanescente APP",
            "Excedente",
          ]}
          indexBy="size"
          margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
          padding={0.55}
          layout="horizontal"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={customColors}
          defs={[
            {
              id: "lines1",
              type: "patternLines",
              background: "#A66C2980",
              color: "#A66C29",
              rotation: 45,
              lineWidth: 6,
              spacing: 13,
            },
            {
              id: "lines2",
              type: "patternLines",
              background: "rgba(128, 198, 77, 0.5)",
              color: "rgb(128, 198, 77)",
              rotation: 45,
              lineWidth: 6,
              spacing: 13,
            },
          ]}
          fill={[
            {
              match: {
                id: "Passivo APP",
              },
              id: "lines1",
            },
            {
              match: {
                id: "Remanescente APP",
              },
              id: "lines2",
            },
          ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisLeft={data[0].size}
          enableGridX={true}
          enableGridY={false}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          tooltip={({ id, value }) => {
            const valueFormated = formatNumber(value, 0, ".", ",");
            return <NivoCustomTooltip id={id} value={valueFormated} />;
          }}
        />
      </div>
      <CustomLegend data={data} />
    </div>
  );
}

export default NivoBar;
