const en = {
  translation: {
    // Global
    select_tenure_category: "Select the land tenure category",
    territory_category: "Select the territory category",
    select_territory: "Select the territory",
    select_state: "Select a state",
    select: "Select or type ...",
    brazil: "Brazil",
    states: "States",
    state: "State",
    municipalities: "Municipalities",
    municipality: "Municipality",
    nameWebSite: "Forest Code Thermometer",
    no_information: "No information",
    error: "An error has occurred",
    downApp: "DOWNLOAD THE APP",
    art: "Art:",
    report: "Report",
    map: "Map",
    settlements: "Settlements",
    rural_properties: "Rural Properties",
    quilombos: "Quilombos",
    zee_status: "zee status",
    biome: "Biome",
    traditional_territories: "Traditional Territories",
    msg_traditional_territories:
      "There is no official data on the demography and location of territories from the 28 segments of Traditional Peoples and Communities in Brazil. The exception concerns indigenous peoples and quilombolas since more information about these collective subjects is available.",
    land_reform_settlements: "Land reform settlements",
    car_area_small_properties: "Registered Area Small Properties",
    car_area_medium_properties: "Registered Area Medium Properties",
    car_area_large_properties: "Registered Area Large Properties",
    forestCodeText:
      "This is the law that regulates land use, especially on rural properties in Brazil. Its aim is to ensure that part of the vegetation on private properties is conserved, with a view to achieving environmental balance for everyone.",
    forestCodeText_2:
      "In May 2012, Law 12.651, the newest version of the Forest Code, was approved, replacing the 1965 version. Today, the main obligations laid down are: the maintenance of Permanent Preservation Areas (APP), the Legal Reserve (RL) and registration in the Rural Environmental Registry (CAR). For properties that need to adapt to the new law, the Environmental Regularization Program (PRA) was created, with a series of benefits for recovering deforested areas.",

    // MENUS
    about: "About",
    objective: "Objective",
    whoDoesIt: "Who does it",
    articles: "Publications",
    code: "Code",
    understand: "Understand",
    glossary: "Glossary",
    contact: "Contact",
    platform: "PLATFORM",
    understandTheData: "Understanding the Data",
    theObservatory: "The Observatory",
    dataMenu: "DATA MENU",
    totalStateArea: "Total area of",
    exploreMap: "Explore the map",
    accessTheReport: "Access the report",
    downloadTheData: "Download the data",
    learnMore: "Learn more",
    objectiveText:
      "To provide data so that society can monitor the implementation of Brazil's Native Vegetation Protection Law, also known as 'the new Forest Code'. With reliable information, the aim is to make decisions that take into account social rights in the countryside, environmental balance and sustainable rural economic development.",
    whoDoesItText:
      "Initiative of the Forest Code Observatory (OCF), developed by the Amazon Environmental Research Institute (IPAM) in collaboration with the Technical and Scientific Committee of OCF member institutions. They are: Instituto Centro de Vida (ICV), Instituto de Manejo e Certificação Florestal e Agrícola (Imaflora), Instituto Socioambiental (ISA), Centro de Sensoriamento remoto da Universidade Federal de Minas Gerais (CSR/UFMG), Amigos da Terra - Amazônia Brasileira (AdT) and Instituto BVRio.",
    layersButton: "All Layers",
    filtersButton: "Filters",
    resetButton: "Reset",

    // Parallax
    theThermometer: "The Thermometer",
    methodology: "Methodology",
    legendParallax:
      "This initiative follows the implementation of the Forest Code (Law No. 12,651 of 2012) in states and municipalities, promoting transparency and social control through the generation of qualified information.",
    accessPlatform: "ACCESS PLATFORM",
    access_platform: "Access platform",
    theForestCodeThermometer: "The Forest Code Thermometer",
    thermometerText:
      "Platform with data and analysis on the implementation of the Forest Code (Law No. 12,651 of 2012) in Brazil, biomes, states and municipalities.",

    // Footer
    anInitiativeOf: "INITIATIVE",
    inColaborationWitch: "COLLABORATION",
    developedBY: "DEVELOPMENT",
    financedBY: "FINANCING",

    // Mapa
    low: "Low",
    know: "Get to know",
    access: "Access",
    high: "High",
    legend: "Legend",
    errorLoadLayer: "Error loading layer. Refresh the page and try again!",
    baseMap: "Base map",
    layers: "Layers",
    to: "to",
    attended: "Attended",
    criterion: "criterion ",
    criteria: "criteria",
    atc: "All criteria attained",
    apc: "Met part of the criteria",
    nca: "No criterion attained",
    layerNotFound: "Layer not found!",
    validatedStateZee: "Validated state ZEE",
    validatedZones: "Validated zones",
    withoutZeeOrNotValidate: "Without ZEE or not validated",
    downloadSuccess: "Download successful.",
    downloadError: "Error downloading.",
    pageSuccess: "Report opened successfully;",
    pageError: "Error opening report.",

    // Tools
    maps: "MAPS",
    tools: "Tools",
    data: "DATA",
    forest_code: "Forest Code",
    statistics: "Statistics",
    reports: "Reports",
    mapsText:
      "Allows you to view a map customized by filters, navigable at municipal, state, national and biome level.",
    dataText:
      "Allows you to download the data that makes up the platform in an .xls file, for a more complete search, according to the user's needs.",
    reportText:
      "Based on the land and territory section selected, it is possible to generate graphs that simplify the visualization and understanding of the data.",
    understandTheDataText:
      "The data is divided into land categories. For each category, it is possible to see the status of compliance with the law, the regulation of instruments such as the PRA and the ZEE, and the level of transparency of public data.",
    settlementsText:
      "Land for agrarian reform in Brazil, aiming to promote social justice and sustainable development in rural areas.",
    traditionalTerritoriesText:
      "Spaces necessary for the cultural, social and economic reproduction of traditional peoples and communities, whether used permanently or temporarily, with regard to indigenous peoples and traditional peoples and communities.",
    ruralPropertiesText:
      "Rural properties for agricultural activities, following the guidelines of the Forest Code for protection of native vegetation and sustainable use of natural resources. According to the number of fiscal modules (FM), rural properties (RP) are classified into small (RP<4FM), medium (4FM > RP ≤ 15 FM) and large (>15 FM).",
    contactText:
      "Send us your questions, requests for information and suggestions.",

    // Relatório
    cccf: "Forest Code Compliance",
    registration: "Registration",
    legality: "legality",
    forestBalance: "Forest Balance",
    overlays: "Overlaps",
    apidl: "Law enforcement actions",
    aev: "Analysis and Validation",
    transparency: "Transparency",
    sdzee: "Ecological-Economic Zoning Status",
    // carVoids: "CAR voids",
    nonRegisteredArea: "Non-Registered area",
    registeredArea: "Registered area",
    surplusRL: "RL Surplus",
    passiveTotal: "Liability total",
    remainingTotal: "Remaining Total",
    passive: "Liability",
    passiveTotalRL: "Liability total RL",
    passiveTotalAPP: "Liability total APP",
    passiveRL: "RL Liability",
    passiveAPP: "APP Liability",
    largeProperties: "Large Properties",
    large: "Large",
    mediumProperties: "Medium Properties",
    medium: "Medium",
    smallProperties: "Small Properties",
    small: "Small",
    remaining: "Remaining",
    remainingRL: "RL Remaining",
    remainingAPP: "APP Remaining",
    surplus: "Surplus",
    totalSurplusRL: "Total Surplus RL",
    areaHa: "Area (ha)",
    embargoes: "Embargoes",
    suppression: "Suppression authorization",

    ASTUC: "Settlement x UC",
    ASTTI: "Settlement x TI",
    ASTQUI: "Settlement x quilombola",
    ASTAST: "Settlement x settlement",
    ASTFPND: "Settlement x public forests",
    ASTIRU: "Settlement x rural properties",

    IRUUC: "Rural properties x UC",
    IRUTI: "Rural properties x TI",
    IRUQUI: "Rural properties x quilombola",
    IRUAST: "Rural properties x settlement",
    IRUFPND: "Rural properties x public forests",

    PCTsUC: "Traditional territories x UC",
    PCTsTI: "Traditional territories x TI",
    PCTsQUI: "Traditional territories x quilombola",
    PCTsAST: "Traditional territories x settlement",
    PCTsFPND: "Traditional territories x public forests",

    nCarAnalyzed: "Number of CAR analyzed",
    nCarValidated: "Number of CAR validated",
    nCarSigned: "Number of Affirmations signed",
    transparencyActive: "Active transparency",
    transparencyPassive: "Passive transparency",

    // Envio de mensagem

    successSendMessage: "Message sent successfully",
    errorSendMessage: "Sorry, could not send message!",
    yourName: "Your name",
    yourMail: "Your email",
    yourMessage: "Your message",
    send: "Send",
    wait: "Wait...",
    mailValid: "Enter a valid email address",

    // Quem somos
    WhoProducesTheThermometer: "Who produces the thermometer",
    WhoProducesTheThermometerText:
      "Since 2018, the Forest Code Observatory (OCF), a network of civil society organizations that monitors the progress of the Brazilian Forest Code, has developed a methodology to analyze and interpret the Rural Environmental Registry (CAR) and to evaluate the Forest Code’s implementation. To that end, a Technical and Scientific Committee was formed with the participation of specialists from OCF member institutions, including the Centro de Vida Institute (ICV), the Institute for Forest and Agricultural Management and Certification (Imaflora), the Amazon Environmental Research Institute (IPAM), Instituto Socioambiental (ISA), the Remote Sensing Center of the Federal University of Minas Gerais (CSR/UFMG), Amigos da Terra – Amazônia Brasileira and BVRio Institute. With periodic discussions, the Committee is always updating and improving on the methodology used on this platform, as well as working on new data according to advances in the implementation of the Forest Code.",
    unknownError: "Unknown error. Try again.",
    cookieConsent:
      "We use cookies to ensure a better browsing experience, as well as tracking cookies to understand how you interact with our website.",
    acceptTerm: "Accept Term",
    reject: "Reject",

    // carStatusConditions: Status e condições do CAR
    carStatus: "CAR Status",
    carConditions: "CAR Conditions",
    Status: "Status",
    Conditions: "Conditions",
    Ativo: "Active",
    Cancelado: "Cancelled",
    Pendente: "Pending",
    Suspenso: "Suspended",
    "Aguardando análise": "Awaiting analysis",
    "Analisado, aguardando regularização ambiental (Lei n 12.651/2012)":
      "Reviewed, awaiting environmental regularization",
    "Analisado, em conformidade com a Lei n 12.651/2012":
      "Reviewed, in accordance with Law 12.651/2012",
    "Analisado, em regularização ambiental (Lei n 12.651/2012)":
      "Reviewed, in environmental regularization",
    "Em análise": "Under review",
  },
};

export default en;
