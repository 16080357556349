import {
  PRA_REQUEST,
  PRA_SUCCESS,
  PRA_FAIL
} from "../constants/praConstants";

const praReducer = (state = {}, action) => {
  switch (action.type) {
    case PRA_REQUEST:
      return { loading: true, praInner: {} };
    case PRA_SUCCESS:
      return { loading: false, praInner: action.payload };
    case PRA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default praReducer;
