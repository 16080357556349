import React from "react";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import i18n from "i18next";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";
import "components/Languages/i18n";

const styles = {
  toastContainer: { fontSize: 15, minWidth: 400, maxWidth: "100%" },
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <>
    <Helmet>
      <title>{i18n.t("nameWebSite")}</title>

      <meta
        name="description"
        content={`${i18n.t("WhoProducesTheThermometerText")} ${i18n.t(
          "legendParallax"
        )}`}
        key="desc"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={process.env.REACT_APP_API_URL} />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_API_URL}/background.jpg`}
      />
      <link rel="icon" href="/favicon.ico" />
      <meta
        name="keywords"
        content={`${i18n.t("nameWebSite")}, ${i18n.t("settlements")}, ${i18n.t(
          "rural_properties"
        )}, ${i18n.t("quilombos")}, ${i18n.t("biome")}, ${i18n.t(
          "traditional_territories"
        )}, ${i18n.t("land_reform_settlements")}, ${i18n.t(
          "statistics"
        )}, ${i18n.t("apidl")}`}
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Router>
      <Provider store={store}>
        <App />
        <ToastContainer style={styles.toastContainer} />
      </Provider>
    </Router>
  </>
);
