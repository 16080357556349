import React, { useCallback, useRef, useState } from "react";

function Controle({
  onChange,
  disabled = false,
  required = false,
  className = "",
  ...rest
}) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const handlerInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handlerInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <input
      onFocus={handlerInputFocus}
      onBlur={handlerInputBlur}
      ref={inputRef}
      className={`tcf-form-control ${className || ""}`}
      disabled={disabled}
      onChange={onChange}
      {...rest}
    />
  );
}

export default Controle;
