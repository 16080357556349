/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Info from "components/Info";
import { useDispatch } from "react-redux";
import { Accordion } from "components";
import * as layerActions from "store/actions/layerActions";
import * as mapActions from "store/actions/mapActions";
import { useLayersStore } from "service/layers.service";
import LayersActions from "./LayersActions";
import RecorteFundiario from "../../Selector/RecorteFundiario";

function Layers({ indexGroup, label, list, group }) {
  const { groups, setGroups } = useLayersStore();
  const [layerItems, setLayerItems] = useState(list);
  const [draggedItem, setDraggedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [ItemInfo, setItemInfo] = useState();

  // reducer
  const dispatch = useDispatch();

  function getObjectByName(array, targetName) {
    return array.find((obj) => obj.name === targetName);
  }

  useEffect(() => {
    // aplica o filtro conforme a Escolha do recorte fundiário
    // Function to get an object from the array by name

    const groupChoise = getObjectByName(groups, label);

    if (groupChoise && groupChoise.name === label) {
      setLayerItems(
        list.filter(
          (l) =>
            l.layer_link.land_cutout === groupChoise.choice.type ||
            l.layer_link.land_cutout === null
        )
      );
    }
  }, [groups, list]);

  const handleDragStart = (e, idx) => {
    setDraggedItem(idx);
  };

  const handleDragEnter = (e, idx, item) => {
    if (draggedItem === null || draggedItem === idx) {
      return;
    }

    const newItems = [...layerItems];
    const draggedItemContent = newItems[draggedItem];

    // Remove the dragged item from its current position
    newItems.splice(draggedItem, 1);

    // Insert the dragged item at the new position
    newItems.splice(idx, 0, draggedItemContent);

    setLayerItems(newItems);
    setDraggedItem(idx); // Update the draggedItem to the new index
    dispatch(
      layerActions.redrawListMenu(indexGroup, draggedItemContent, newItems)
    );
  };

  const handleDragEnd = () => {
    dispatch(mapActions.handleOrderLayer({ layerItems }));
    setDraggedItem(null);
  };

  // const handleSetGroups = () => {
  //   const newsGroups = groups.map((obj) => {
  //     if (obj.name === group.name) {
  //       // If the object's name matches the target name, update the data
  //       const currentActive = true;
  //       return { ...obj, currentActive };
  //     }
  //     // Otherwise, return the original object unchanged
  //     const currentActive = false;
  //     return { ...obj, currentActive };
  //   });
  //   setGroups(newsGroups);
  // };

  // const item = getObjectByName(groups, group?.name);

  const openModal = (item) => {
    setShowModal((prev) => !prev);
    setItemInfo(item);
  };

  return (
    <Accordion
      className="tcf-sidebar__territory--selector-layer"
      size="sm"
      title={label}
      space
      /*       active={item?.currentActive} */
      content={
        layerItems !== null && (
          <div className="tcf-sidebar__territory--selector-layer-items">
            {/* essa não é a melhor forma de fazer uma verificação, mas como não tinha uma key definida, foi obrigado fazer assim */}
            {(label === "Conformidade com o Código Florestal" ||
              label === "Forest Code Compliance") && (
                <RecorteFundiario group={group} list={list} />
              )}
            {typeof layerItems === "object" &&
              layerItems.map((item, idx) => (
                <div
                  className={`tcf-sidebar__territory--selector-layer-item${item.layer_link.activated
                      ? " tcf-sidebar__territory--selector-layer-item--active"
                      : ""
                    }${draggedItem === idx ? " tcf-dragging" : ""}`}
                  key={`key_${idx}`}
                  style={
                    !item.layer_link.link && item.layer_link.is_wms
                      ? {
                        opacity: 0.5,
                      }
                      : {}
                  }
                  onDragEnter={(e) => handleDragEnter(e, idx, item)}
                  onDragEnd={handleDragEnd}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <div className="tcf-sidebar__territory--selector-layer-item-info">
                    <span
                      draggable
                      onDragStart={(e) => handleDragStart(e, idx)}
                    >
                      <svg
                        width="8"
                        height="12"
                        viewBox="0 0 8 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.2 2.4C1.86274 2.4 2.4 1.86274 2.4 1.2C2.4 0.537258 1.86274 0 1.2 0C0.537258 0 0 0.537258 0 1.2C0 1.86274 0.537258 2.4 1.2 2.4Z"
                          fill="#AFA7A1"
                        />
                        <path
                          d="M1.2 7.19999C1.86274 7.19999 2.4 6.66273 2.4 5.99999C2.4 5.33725 1.86274 4.79999 1.2 4.79999C0.537258 4.79999 0 5.33725 0 5.99999C0 6.66273 0.537258 7.19999 1.2 7.19999Z"
                          fill="#AFA7A1"
                        />
                        <path
                          d="M1.2 12C1.86274 12 2.4 11.4627 2.4 10.8C2.4 10.1372 1.86274 9.59998 1.2 9.59998C0.537258 9.59998 0 10.1372 0 10.8C0 11.4627 0.537258 12 1.2 12Z"
                          fill="#AFA7A1"
                        />
                        <path
                          d="M6 2.4C6.66274 2.4 7.2 1.86274 7.2 1.2C7.2 0.537258 6.66274 0 6 0C5.33726 0 4.8 0.537258 4.8 1.2C4.8 1.86274 5.33726 2.4 6 2.4Z"
                          fill="#AFA7A1"
                        />
                        <path
                          d="M6 7.19999C6.66274 7.19999 7.2 6.66273 7.2 5.99999C7.2 5.33725 6.66274 4.79999 6 4.79999C5.33726 4.79999 4.8 5.33725 4.8 5.99999C4.8 6.66273 5.33726 7.19999 6 7.19999Z"
                          fill="#AFA7A1"
                        />
                        <path
                          d="M6 12C6.66274 12 7.2 11.4627 7.2 10.8C7.2 10.1372 6.66274 9.59998 6 9.59998C5.33726 9.59998 4.8 10.1372 4.8 10.8C4.8 11.4627 5.33726 12 6 12Z"
                          fill="#AFA7A1"
                        />
                      </svg>
                    </span>
                    <span className="tcf-sidebar__territory--selector-layer-item-info--title">
                      {item.name}
                    </span>
                    <button
                      type="button"
                      className="tcf-map-layer-show-popover"
                      onClick={() => openModal(item)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M7.20001 13C10.5137 13 13.2 10.3137 13.2 7C13.2 3.68629 10.5137 1 7.20001 1C3.8863 1 1.20001 3.68629 1.20001 7C1.20001 10.3137 3.8863 13 7.20001 13Z"
                          stroke="#AFA7A1"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.20001 9.4V7"
                          stroke="#AFA7A1"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.20001 4.60004H7.20601"
                          stroke="#AFA7A1"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <Info
                      item={ItemInfo}
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                  </div>
                  <LayersActions
                    indexGroup={indexGroup}
                    item={item}
                    listAll={list}
                  />
                </div>
              ))}
          </div>
        )
      }
    />
  );
}

Layers.propTypes = {
  label: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default Layers;
