import React from "react";
import Article from "components/Article";
import myImage1 from "assets/post1.png";
import myImage2 from "assets/post2.png";

const artigos = [
  {
    title:
      "Caatinga e o Código Florestal: desafios e oportunidades para a conservação",
    imagem: myImage1,
    link: "",
  },
  {
    title: "Código Florestal na Amazônia e regularização fundiária",
    imagem: myImage2,
    link: "",
  },
  {
    title:
      "Caatinga e o Código Florestal: desafios e oportunidades para a conservação",
    imagem: myImage1,
    link: "",
  },
];

function Blog() {
  return (
    <div className="blog_container" id="articles">
      <h1>Publicações</h1>
      <div className="tcf-articles__container">
        {artigos.map((artigo, index) => (
          <Article
            key={index}
            className="tcf-article"
            link={artigo.link}
            imagem={artigo.imagem}
            title={artigo.title}
          />
        ))}
      </div>
    </div>
  );
}

export default Blog;
