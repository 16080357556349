/* eslint-disable prettier/prettier */
import React from "react";
import "../Languages/i18n";
import i18n from "i18next";
import { Button } from "components";
import Languages from "../Languages";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { scrollTo } from "utils/general-functions";

const items = [
  {
    shared: false,
    name: i18n.t("about"),
    id: "about",
    key: "about",
    href: "/#about",
  },
  {
    shared: false,
    name: i18n.t("articles"),
    id: "articles",
    key: "articles",
    href: "/#articles",
  },
  {
    shared: false,
    name: i18n.t("code"),
    id: "code",
    key: "code",
    href: "/#code",
  },
  {
    shared: false,
    name: i18n.t("tools"),
    id: "tools",
    key: "tools",
    href: "/#tools",
  },
  {
    shared: false,
    name: i18n.t("understand"),
    id: "understand",
    key: "understand",
    href: "/#understand",
  },
  {
    shared: false,
    name: i18n.t("methodology"),
    id: "methodology",
    key: "methodology",
    href: "/metodologia",
  },
  {
    shared: true,
    name: i18n.t("contact"),
    id: "contact",
    key: "contact",
    href: "#contact",
  },
];

function Menu() {
  const { pathname } = window.location;
  function checkPath(path) {
    const parts = path.split("/");
    const lastPart = parts.pop();

    if (lastPart !== "") {
      return path;
    }
    return "/";
  }

  return (
    <ul className="tcf-nav-items">
      {items.map((item, idx) => (
        <li className="tcf-nav-item" key={idx}>
          <Button
            href={item.shared ? checkPath(pathname) + item.href : item.href}
            variant="link-dark"
            size="md"
            key={idx}
            onClick={() => { scrollTo(item.id) }}
          >
            {item.name}
          </Button>
        </li>
      ))}

      <li className="tcf-nav-item">
        <Link
          to="/plataforma"
          style={{
            textDecoration: "none",
          }}
        >
          <Button variant="outline-primary" size="md">
            {i18n.t("platform")}
          </Button>
        </Link>
      </li>

      <li className="tcf-nav-lang">
        <Languages
          vertical={false}
          styleContainer={{ position: "initial", top: 0, right: 0.5 }}
        />
      </li>
    </ul>
  );
}

export default withTranslation()(Menu);
