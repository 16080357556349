import React from "react";
import ConsentCookie from "components/CookieConsent";
import { SharedValuesProvider } from "service/context";
import { MapStoreProvider } from "service/map.context";
import { ReportStoreProvider } from "service/report.service";
import { LayersStoreProvider } from "service/layers.service";
import Map from "./Map";

function Platform() {
  return (
    <SharedValuesProvider>
      <MapStoreProvider>
        <LayersStoreProvider>
          <ReportStoreProvider>
            <Map />
            <ConsentCookie />
          </ReportStoreProvider>
        </LayersStoreProvider>
      </MapStoreProvider>
    </SharedValuesProvider>
  );
}

export default Platform;
