/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";
import React from "react";
import { Button, ButtonGroup, Divider } from "components";

function Languages({ vertical = true, center }) {
  /*
   * Para alterar o idioma
   */
  const handleLanguage = (lang) => {
    localStorage.setItem("@language", lang);
    window.location.reload();
  };

  return (
    <ButtonGroup vertical={vertical} center={center}>
      <Button
        variant="link-light"
        size="md"
        name="pt-BR"
        onClick={() => handleLanguage("pt-BR")}
      >
        PT
      </Button>
      <Divider vertical={vertical} />
      <Button
        variant="link-dark"
        size="md"
        name="en-US"
        onClick={() => handleLanguage("en-US")}
      >
        EN
      </Button>
    </ButtonGroup>
  );
}

Languages.propTypes = {
  vertical: PropTypes.bool.isRequired,
  styleContainer: PropTypes.object.isRequired,
};

export default Languages;
