import ReactToPrint from "react-to-print";
import DownloadSvg from "./downloadSVG";
import PropTypes from "prop-types";
import "./style.css";

function DownloadGraphFunction({ documentRef, documentTitle = "" }) {
  return (
    <ReactToPrint
      trigger={() => DownloadSvg}
      content={() => documentRef?.current}
      documentTitle={documentTitle || ""}
    />
  );
}

DownloadGraphFunction.propTypes = {
  documentRef: PropTypes.any,
  documentTitle: PropTypes.any,
};

export default DownloadGraphFunction;
