import React, { useState } from "react";
import Opacity from "../ToolBox/Opacity";
import Switch from "../ToolBox/Switch";
// import LayerOrder from "../ToolBox/LayerOrder";
// import * as mapActions from "store/actions/mapActions";
// import { useDispatch } from "react-redux";

function LayersActions({ indexGroup, listAll, item }) {
  const [active, setActive] = useState(false);

  return (
    <div className="tcf-sidebar__territory--selector-layer-item-action">
      <Opacity active={active} setActive={setActive} item={item} />
      {!active && (
        <Switch
          key={item.id}
          indexGroup={indexGroup}
          item={item}
          listAll={listAll}
        />
      )}
    </div>
  );
}

export default LayersActions;
