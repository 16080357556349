import {
  CARSTATUSCONDITION_REQUEST,
  CARSTATUSCONDITION_SUCCESS,
  CARSTATUSCONDITION_FAIL,
} from "../constants/carStatusConditionConstants";

const carStatusConditionReducer = (state = {}, action) => {
  switch (action.type) {
    case CARSTATUSCONDITION_REQUEST:
      return { loading: true, carInner: {} };
    case CARSTATUSCONDITION_SUCCESS:
      return { loading: false, carInner: action.payload };
    case CARSTATUSCONDITION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default carStatusConditionReducer;
