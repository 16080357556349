export const scrollTo = (target) => {
  const element = document.getElementById(target);

  if (element) {
    const top =
      element.getBoundingClientRect().top +
      (window.scrollY || window.pageYOffset) -
      100;

    window.scrollTo({ top, behavior: "smooth" });
  } else {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
