import React from "react";
import { useReportStore } from "service/report.service";
import ZoomToExtent from "../ZoomToExtent";
import BaseLayer from "../BaseLayer";
import ControlLanguages from "./ControlLanguages";
import ControlInOut from "./ControlInOut";
import ControlInLegendIndicator from "./ControlInLegendIndicator";
import LayersSupport from "../LayersSupport";

function Controls() {
  const { showReport } = useReportStore();
  return (
    <div
      className="tcf-map--controls"
      style={{ display: `${showReport ? "none" : "flex"}` }}
    >
      <ControlLanguages />
      <BaseLayer />
      <LayersSupport />
      <ControlInLegendIndicator />
      <ControlInOut />
      <ZoomToExtent />
    </div>
  );
}

export default Controls;
