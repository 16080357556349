import React from "react";

function Textarea({ name, placeholder, defaultValue, id, disabled, onChange }) {
  return (
    <textarea
      className="tcf-form-control"
      name={name}
      id={id}
      defaultValue={defaultValue}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
    />
  );
}

export default Textarea;
