/* eslint-disable import/extensions */
import PropTypes from "prop-types";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import variablePie from "highcharts/modules/variable-pie.js";
import React, { useRef } from "react";
import { color } from "styles/Theme";

variablePie(Highcharts);

const DoubleDonut = ({ data, height }) => {
  const chartRef = useRef();

  const options = {
    chart: {
      type: "pie",
      height: height,
      backgroundColor: color.white,
    },
    title: { text: null },
    credits: { enabled: false },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: { size: 0 },
          },
        },
        dataLabels: { enabled: false },
      },
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    tooltip: {
      style: {
        color: "#505050",
        fontSize: "13px",
      },
      formatter: () => {
        const { y, percentage, point, series } = this;

        const { values1 } = data;
        const [item1, item2] = values1;
        // o gráfico interno são 2 grupos de 3 valores,
        // então se o index for <= 2 pega o item1 do gráfico externo
        // senão pega o item2 do gráfico externo
        const value = point.index <= 2 ? item1.y : item2.y;

        // se for o gráfico externo pega a porcentagem correspondente,
        // senão, faz a divisão do valor interno com o valor externo.
        const perc = series.index === 0 ? percentage : (y / value) * 100;

        return `${point.name}
          :
          ${Highcharts.numberFormat(Math.abs(y), 0, ",", ".")}
          ha
          (${Highcharts.numberFormat(perc, 1)}%)`;
      },
    },
    series: [
      {
        innerSize: 150,
        size: 200,
        data: data.values1,
      },
    ],
  };

  // se existir dados no values2, adicionar no "series"
  if (data.values2)
    options.series.push({
      innerSize: 100,
      size: 150,
      data: data.values2,
    });

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
};

DoubleDonut.propTypes = {
  data: PropTypes.object,
  height: PropTypes.string,
};

export default DoubleDonut;
