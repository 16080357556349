import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { direction, alignment, color } from "styles";
import i18n from "i18next";
import HTMLRenderer from "components/HTMLRenderer";
import stripHTMLTags from "utils/stripHTMLTags";
import Accordion from "components/Accordion";
import LearnMore from "./LearnMore";

const UnderstandDatas = () => {
  const { sobreInner } = useSelector((state) => state.sobre);

  const sobre =
    (sobreInner &&
      sobreInner.filter(
        (section) => section.name_section === "understandingTheData"
      )) ||
    "";

  const section = sobre[0] || {};

  const arr = section.about_topic_caption || [];

  const paragraphOne = section.text_topic
    ? section.text_topic.split("</p>")
    : "";
  const arrEntendaDados = [
    {
      caption: i18n.t("settlements").toUpperCase(),
      value: "settlements",
    },
    {
      caption: i18n.t("rural_properties").toUpperCase(),
      value: "rural_properties",
    },
    {
      caption: i18n.t("traditional_territories").toUpperCase(),
      value: "traditional_territories",
    },
  ];
  return (
    <div className="tcf-methodology-understand">
      <div className="tcf-container">
        <div className="tcf-row">
          <div className="tcf-col-12 tcf-methodology-understand__title">
            <h2 className="tcf-subtitle">{section.title}</h2>
            <p className="tcf-paragraph">
              {stripHTMLTags(paragraphOne && paragraphOne[0])}
            </p>
            {/*  <HTMLRenderer
              content={paragraphOne && paragraphOne[0]}
              className="tcf-paragraph"
            /> */}
          </div>

          <div className="tcf-col-12">
            {arrEntendaDados.map((territory, idx) => (
              <Accordion
                key={idx}
                size="md"
                title={territory?.caption}
                content={
                  <>
                    <div>
                      {territory.value === "traditional_territories" ? (
                        <div>
                          <p>{i18n.t("msg_traditional_territories")}</p>
                          <LearnMore />
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {arr.map(
                        (item, idx) =>
                          territory.value === item.cod_territory && (
                            <Accordion
                              size="sm"
                              title={item?.caption}
                              key={idx}
                              content={
                                <div>
                                  {item.about_caption_item?.map((item, idx) => (
                                    <Accordion
                                      key={idx}
                                      size="sm"
                                      title={item.item}
                                      content={
                                        <HTMLRenderer
                                          content={item.text_item}
                                        />
                                      }
                                    />
                                  ))}

                                  {item.text_caption ? (
                                    <div>
                                      <HTMLRenderer
                                        content={item.text_caption}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              }
                            />
                          )
                      )}
                    </div>
                  </>
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderstandDatas;
