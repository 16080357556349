/* import Observatorio from "./Observatorio"; */
import SobreOTermometro from "./SobreOTermometro";
import EntendaOsDados from "./EntendaOsDados/EntendaOsDados";
import Ferramentas from "./Ferramentas";
import CodigoFlorestal from "./SobreOTermometro/CodigoFlorestal";

const Landingpage = {
  CodigoFlorestal,
  SobreOTermometro,
  EntendaOsDados,
  Ferramentas,
};

export default Landingpage;
