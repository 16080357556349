import React from "react";
import CountUp from "react-countup";

const AnimationCount = ({ value, color = "#ccc" }) => (
  <CountUp
    end={value}
    duration={3}
    separator="."
    decimals={0}
    decimal=","
    style={{ fontSize: "44px", color: color, fontWeight: "bold" }}
  />
);

export default AnimationCount;
