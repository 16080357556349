import React from "react";
import { Link } from "react-router-dom";
import { scrollTo } from "utils/general-functions";

function Logo({ url }) {
  return (
    <Link to="/" onClick={() => scrollTo("home")}>
      <img src={url} className="tcf-logo" />
    </Link>
  );
}

export default Logo;
