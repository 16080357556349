/* eslint-disable react/jsx-no-useless-fragment */
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TeritorialCut from "./TeritorialCut";
import {
  Cadastramento,
  CarStatus,
  CarConditions,
  Legalidade,
  Sobreposicoes,
  StatusZEE,
  Transparencia,
} from "./Pages";
import Remanescente from "./Pages/Remanescente";
import Excedente from "./Pages/Excedente";
import Passivo from "./Pages/Passivo";

function Body() {
  const choice = useSelector((state) => state.choice);
  const { type, territorialCut } = choice || {};

  const { sobreInner } = useSelector((state) => state.sobre);

  const sobre =
    (sobreInner &&
      sobreInner.filter(
        (section) => section.name_section === "understandingTheData"
      )) ||
    "";

  const section = sobre[0] || {};

  const arr = section.about_topic_caption || [];

  const [infoMethod, getInfoMethod] = useState(
    arr.filter((e) => e.cod_territory === choice.type)
  );

  const getInfo = (keyPT, keyEN) => {
    const mt = infoMethod;

    const methodology = mt.filter(
      (e) => e.caption === keyPT || e.caption === keyEN
    );

    const posZero = methodology[0];
    return posZero?.about_caption_item;
  };

  // todos os níveis
  const originalAllLevelsArray = [
    {
      title: `${i18n.t("cccf")}: ${i18n.t("registration")}`, // "Conformidade com o Código Florestal: cadastramento"
      component: <Cadastramento />,
      info: getInfo("Cadastramento", "Registration"),
      col: "",
    },
    {
      title: `${i18n.t("cccf")}: ${i18n.t("overlays")}`, // "Conformidade com o Código Florestal: sobreposições",
      component: <Sobreposicoes />,
      info: getInfo("Sobreposições", "Overlaps"),
      col: "",
    },
    {
      title: `${i18n.t("cccf")}: ${i18n.t("forestBalance")}`, // "Conformidade com o Código Florestal: legalidade",
      component: <Legalidade />,
      info: getInfo("Balanço florestal", "Forest Balance"),
      col: "full",
      hasFilter: true,
    },
    {
      title: `${i18n.t("cccf")}: ${i18n.t("passive")}`, // "Conformidade com o Código Florestal: Passivo",
      component: <Passivo />,
      info: getInfo("Passivo", "Passivo"),
      col: "full",
    },
    {
      title: `${i18n.t("cccf")}: ${i18n.t("remaining")}`, // "Conformidade com o Código Florestal: remanescente",
      component: <Remanescente />,
      info: getInfo(
        "Remanescente de vegetação nativa",
        "Remanescente de vegetação nativa"
      ),
      col: "full",
    },
    {
      title: `${i18n.t("cccf")}: ${i18n.t("surplus")}`, // "Conformidade com o Código Florestal: Excedente",
      component: <Excedente />,
      info: getInfo("Excedente RL", "Excedente RL"),
      col: "full",
    },
    {
      title: i18n.t("transparency"), // "Transparência",
      component: <Transparencia />,
      info: getInfo("Transparência", "Transparency"),
      col: "fullplus",
    },
    /* {
      title: i18n.t("carStatus"), // "CAR status",
      component: <CarStatus />,
      col: "fullplus",
    }, */
    {
      title: i18n.t("carConditions"), // "CAR condições",
      component: <CarConditions />,
      col: "fullplus",
    },
  ];

  // recorte territorial apenas nível estado
  const originalStateLevelsArray = [
    {
      title: i18n.t("sdzee"), // "Status do Zoneamento Ecológico-Econômico",
      component: <StatusZEE />,
      info: getInfo("zee_status", "zee_status"),
      col: "full",
    },
  ];

  const [arr1, setArr1] = useState(originalAllLevelsArray);

  useEffect(() => {
    let updatedArr1 = [...originalAllLevelsArray];

    // Se selecionar "Territórios tradicionais", remove tudo a partir da posição 2
    if (type === "traditional_territories") {
      updatedArr1 = updatedArr1.slice(0, 2);
    }

    // Se selecionar "Assentamentos" e recorte territorial NÃO for "estado", remove Transparência
    if (type === "settlements" && territorialCut?.value !== "state") {
      updatedArr1 = updatedArr1.filter(
        (x) => x.title !== i18n.t("transparency")
      );
    }

    // Só exibe recortes do CAR no nível de propriedade rural
    if (type !== "rural_properties") {
      updatedArr1 = updatedArr1.filter(
        (x) =>
          x.title !== i18n.t("carStatus") && x.title !== i18n.t("carConditions")
      );
    }

    // Só exibe recortes do CAR no nível de propriedade rural
    if (territorialCut?.value !== "state") {
      updatedArr1 = updatedArr1.filter((x) => x.title !== i18n.t("carStatus"));
    }

    setArr1(updatedArr1);
  }, [choice]);

  useEffect(() => {
    if (arr.length !== 0)
      getInfoMethod(arr.filter((e) => e.cod_territory === choice.type));
  }, [choice, arr]);

  return (
    <div className="tcf-map-report--body">
      <div className="tcf-map-report--body-territorialcut">
        {arr1.map((item, idx) => (
          <TeritorialCut index={idx} key={idx} item={item} />
        ))}
        {territorialCut?.value === "state" && (
          <>
            {originalStateLevelsArray.map((item, idx) => (
              <TeritorialCut key={idx} item={item} />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default Body;
