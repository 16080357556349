/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState, useCallback } from "react";

function SearchSelect({
  placeholder,
  onChange,
  options,
  disabled = false,
  isLoading = false,
}) {
  const container = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Função para selecionar uma opção
  const handleSelect = (option) => {
    onChange(option);
    setSelectedOption(option);
    setSearchTerm(option.label);
    setIsOpen(false);
  };

  // Função de busca com debounce para evitar rerenderizações frequentes
  const handleSearch = useCallback(
    (e) => {
      const term = e.target.value.toLowerCase();
      setSearchTerm(term);
      setFilteredOptions(
        options.filter((option) => option.label.toLowerCase().includes(term))
      );
    },
    [options]
  );

  // Função para detectar clique fora do componente
  const handleClickOutside = useCallback(
    (event) => {
      if (
        isOpen &&
        container.current &&
        !container.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  // Atualizar as opções filtradas quando as originais mudarem
  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  // Adiciona e remove o evento de clique fora do componente
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [container]);

  return (
    <div className="tcf-form-select" ref={container}>
      <div
        className={`tcf-form-select-header ${isOpen ? "open" : ""} ${
          selectedOption ? selectedOption || "" : "placeholder"
        } ${disabled ? "disabled" : isLoading ? "disabled" : ""}`}
        onClick={toggleDropdown}
      >
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder={placeholder}
          className=""
          style={{
            border: "0px",
          }}
        />
        {isLoading ? (
          <div className="tcf-form-select-loading" />
        ) : (
          <div className="css-tcf-select-indicatorContainer" aria-hidden="true">
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-cf-select-Svg"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
            </svg>
          </div>
        )}
      </div>
      {isOpen && (
        <ul className="tcf-form-select-options">
          {filteredOptions.map((option) => (
            <li
              key={option.value}
              className={`${
                selectedOption === option.value
                  ? "tcf-form-select-option-selected"
                  : ""
              } ${isOpen ? "tcf-form-select-animate" : ""}`}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SearchSelect;
