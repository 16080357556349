import api from "service/axios.service";
import {
  CADASTRAMENTO_FAIL,
  CADASTRAMENTO_REQUEST,
  CADASTRAMENTO_SUCCESS,
} from "../constants/cadastramentoConstants";

export const cadastramentoData = (choice) => async (dispatch) => {
  const { type, territorialCut, territory } = choice || {};
  const { value: valueCut } = territorialCut || {};
  const { label, value } = territory || {};

  dispatch({ type: CADASTRAMENTO_REQUEST });
  try {
    const newValueCut =
      valueCut === "rural_properties" || valueCut === "traditional_territories"
        ? "municipality"
        : valueCut;

    const res = await api.post(`/v2/registrationTerritory/${newValueCut}/`, {
      codTerritory: type,
      nameTerritory: [label],
      geocodes: [value],
    });

    const { data } = res || {};
    const [values] = data || [];

    dispatch({
      type: CADASTRAMENTO_SUCCESS,
      payload: values || {},
    });
  } catch (error) {
    dispatch({
      type: CADASTRAMENTO_FAIL,
      payload: error.response?.data.message
        ? error.message.data.message
        : error.message,
    });
  }
};
