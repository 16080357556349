/* eslint-disable react/jsx-no-useless-fragment */
import i18n from "i18next";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { carData } from "store/actions/carStatusConditionActions";
import AnimationCount from "./AnimationCount";

const CarCondition = () => {
  const dispatch = useDispatch();
  const choice = useSelector((state) => state.choice);
  const { carInner } = useSelector((state) => state.car);
  const { territory } = choice || {};
  const { condition } = carInner || {};

  useEffect(() => {
    if (territory?.value) dispatch(carData(choice));
  }, [territory]);

  if (!territory?.value)
    return <div style={{ width: "100%" }}>{i18n.t("select_territory")}</div>;

  return (
    <>
      <div className="tcf-map-report--body-territorialcut--ischildren">
        {condition?.conditions?.map(({ label, value }, idx) => (
          <div
            key={idx}
            className="tcf-map-report--body-territorialcut--ischildren-item"
          >
            <div className="tcf-map-report--body-territorialcut--ischildren-item--title">
              {label}
            </div>
            <div className="tcf-map-report--body-territorialcut--ischildren-item--body box-counter">
              <AnimationCount value={Number(value)} color="#78C753" />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CarCondition;
