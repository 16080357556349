/* eslint-disable prettier/prettier */
import React from "react";


function Check({ id, checked, onChange, disabled = false, label, type }) {
  const inProps = {
    isRadio: type === "radio",
    isSwitchClass: type === "switch" ? "tcf-form-switch" : "tcf-form-radio",
  };

  return (
    <div className={`tcf-form-check ${inProps.isSwitchClass}`} >
      <input
        id={id}
        type={inProps.isRadio ? "radio" : "checkbox"}
        className="tcf-form-check-input"
        checked={checked}
        disabled={disabled}
        onChange={(e) => inProps.isRadio ? onChange(e) : onChange(e.target.checked)}
      />

      {label && (
        <label htmlFor={id} className="form-check-label">
          {label}
          <div />
        </label>
      )}
    </div>
  );
}

export default Check;
