/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

function ControlLanguages() {
  /*
   * Para alterar o idioma
   */
  const [currentLang, setCurrentLang] = useState("pt-BR");
  const handleLanguage = (lang) => {
    localStorage.setItem("@language", lang);
    window.location.reload();
  };

  useEffect(() => {
    const language = localStorage.getItem("@language");
    if (language) {
      setCurrentLang(language);
    }
  }, []);

  return (
    <div className="tcf-map--control-langs tcf-map--control__btn--group">
      <div className="tcf-map--control__btn--container">
        <button
          className={
            currentLang === "pt-BR"
              ? "tcf-map--control__btn tcf-map--control__btn--active"
              : "tcf-map--control__btn"
          }
          onClick={() => handleLanguage("pt-BR")}
        >
          PT
        </button>
      </div>
      <div className="tcf-map--control__btn--container">
        <button
          className={
            currentLang === "en-US"
              ? "tcf-map--control__btn tcf-map--control__btn--active"
              : "tcf-map--control__btn"
          }
          onClick={() => handleLanguage("en-US")}
        >
          EN
        </button>
      </div>
    </div>
  );
}

export default ControlLanguages;
