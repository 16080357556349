import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import "components/Languages/i18n";
import Landing from "pages/landing";
import Plataforma from "pages/platform";
import Metodologia from "pages/methodology";
import Error404 from "pages/404";
import TesteEstilo from "styles/testeEstilo";
import { linksAppAction } from "./store/actions/linksAppActions";
import { sobreActions } from "./store/actions/sobreActions";
import { initGA } from "./utils/ga-utils";
import { methodologyActions } from "./store/actions/methodologyActions";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    initGA("G-396SX5SLK0");
  }, []);

  useEffect(() => {
    dispatch(sobreActions());
  }, []);
  useEffect(() => {
    dispatch(linksAppAction());
  }, []);
  useEffect(() => {
    dispatch(methodologyActions());
  }, []);

  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/plataforma" element={<Plataforma />} />
      <Route exact path="/metodologia" element={<Metodologia />} />
      <Route exact path="/testeEstilo" element={<TesteEstilo />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export default withTranslation()(App);
