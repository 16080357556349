import { useEffect } from "react";
import { showToast } from "helpers/Toast";
import { useSelector } from "react-redux";
import StatisticsButton from "../StatisticsButton";
import ReportButton from "../ReportButton";
import PdfButton from "../pdfButton";

function Actions() {
  const { message, type } = useSelector((state) => state.statistics);

  useEffect(() => {
    if (type) showToast(message, { type: type });
  }, [message, type]);

  return (
    <div className="tcf-sidebar__header--actions">
      <ReportButton />
      <StatisticsButton />
      <PdfButton />
    </div>
  );
}

export default Actions;
