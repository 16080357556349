import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { glossarioActions } from "store/actions/glossarioActions";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { HTMLRenderer, Accordion } from "components";

const Glossary = () => {
  const dispatch = useDispatch();
  const { glossarioInner } = useSelector((state) => state.glossario);
  const glossario = glossarioInner || "";

  useEffect(() => {
    dispatch(glossarioActions());
  }, [dispatch]);

  return (
    <div className="tcf-glossary" id="glossary">
      <div className="tcf-container">
        <div className="tcf-row">
          <div className="tcf-col-12">
            <h2 className="tcf-subtitle">{i18n.t("glossary")}</h2>
          </div>
          <div className="tcf-col-12">
            {glossario &&
              glossario.map((item, idx) => (
                <Accordion
                  key={idx}
                  title={item.tag_name}
                  content={<HTMLRenderer content={item.tag_text} />}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Glossary);
